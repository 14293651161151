import {platform} from "@/api/platform";
import {retryRequest} from "@/helpers/request";
import emitter, {EVENTS} from "@/api/events";

export default {
    namespaced: true,
    state: {
        loading: false,
        type: null,
        cart: null
    },
    getters: {
        loading: state => state.loading,
        type: state => JSON.parse(JSON.stringify(state.type)),
        cart: state => null !== state.cart ? JSON.parse(JSON.stringify(state.cart)) : JSON.parse(JSON.stringify(state.type))
    },
    mutations: {
        setLoading: (state, loading) => state.loading = loading,
        setType: (state, type) => state.type = type,
        setCart: (state, type) => state.cart = type,
    },
    actions: {
        fetch(context) {

            context.commit("setLoading", true);

            return retryRequest(() => platform.get(`/api/v2/user/${this.getters["user/id"]}/constraints`))
                .then(response => {

                    context.commit("setType", response.data.results.constraints.type);
                    context.commit("setLoading", false);

                    emitter.emit(EVENTS.USER_CONSTRAINTS_FETCH);
                });
        },
        fetchCart(context) {

            context.commit("setLoading", true);

            const opts = {params: {useCart: true}};

            return retryRequest(() => platform.get(`/api/v2/user/${this.getters["user/id"]}/constraints`, opts))
                .then(response => {

                    context.commit("setCart", response.data.results.constraints.type);
                    context.commit("setLoading", false);

                    emitter.emit(EVENTS.USER_CONSTRAINTS_FETCH_CART);
                });
        },
        clear(context) {

            context.commit("setType", null);
            context.commit("setCart", null);
        }
    }
}