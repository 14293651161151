<template>
    <div class="views-order-submit">
        <div v-if="0 === products.length" class="container">
            <div class="row">
                <div class="col-12">
                    {{t('noItems')}}
                </div>
            </div>
        </div>
        <div v-else-if="true === loading" class="container">
            <div class="row">
                <div class="col-12">
                    <loading/>
                </div>
            </div>
        </div>
        <layout-two-column v-else :inverse="true">
            <template #column>
                <panel class="panel-secondary">
                    <!-- New Address Form -->
                    <address-form v-if="display.addressform || forceNewAddress"
                                  :tags="computedTags"
                                  :hide-cancel="forceNewAddress"
                                  @saved="addressId => {model.addressId = addressId; setDisplay('checkout');}"
                                  @cancel="setDisplay('checkout')"/>

                    <!-- Incomplete address form -->
                    <address-form v-if="display.addresscompleteform"
                                  :tags="computedTags"
                                  :value="model.address"
                                  @saved="addressId => {model.addressId = addressId;setDisplay('checkout');submit()}"
                                  @cancel="setDisplay('checkout')"/>

                    <form v-if="(display.checkout) && !forceNewAddress"
                          @submit.prevent="base_submit('model', 'submit')">

                        <div v-for="field in checkoutConfigBeforeAddress" class="py-1" :key="field.payload.field">
                            <component v-model="model[field.payload.field]"
                                       :is="checkout.types[field.type]"
                                       :validator="v$.model[field.payload.field]"
                                       :label="field.label ? (this.$te('checkout_config.' + field.payload.field + '.' + field.label) ? this.$t('checkout_config.' + field.payload.field + '.' + field.label) : field.label) : null"
                                       :placeholder="field.placeholder ? this.$t('checkout_config.' + field.payload.field + '.' + field.placeholder) : null"
                                       :disabled="processing||field.disabled"
                                       :message-not-found="dynamic_field_messageNotFound(field, 'checkout_config.' + field.payload.field)"
                                       :infinity-scroll="dynamic_field_infinityScroll(field)"
                                       :strict="field.typeahead && field.typeahead.strict"
                                       :min-date="form_dynamic_getDatepickerMinDate(field)"
                                       :max-date="form_dynamic_getDatepickerMaxDate(field)"
                                       :milliseconds="true"
                                       :disable-dates="form_dynamic_getDatepickerDisableDates(field)"
                                       @search="(value, render) => dynamic_field_typeahead(field, value, render)"/>
                        </div>

                        <address-selector v-model="model.addressId" :tags="computedTags" :disabled="processing"/>

                        <div v-if="v$.model.addressId.required.$invalid" class="text-danger small text-center pb-2">
                            {{t('model.addressId.errors.required')}}
                        </div>

                        <div v-if="v$.model.addressId.noAddress.$invalid" class="text-danger small text-center pb-2">
                            {{t('model.addressId.errors.noAddress')}}
                        </div>

                        <div v-if="!denyAddressCreation" class="text-center py-2">
                            <button type="button" class="btn btn-sm btn-link"
                                    :disabled="processing"
                                    @click="setDisplay('addressform')">
                                {{t('createNewAddress')}}
                            </button>
                        </div>

                        <div v-for="field in checkoutConfigAfterAddress" class="py-1" :key="field.payload.field">
                            <component v-model="model[field.payload.field]"
                                       :is="checkout.types[field.type]"
                                       :validator="v$.model[field.payload.field]"
                                       :mask="field.mask"
                                       :label="field.label ? (this.$te('checkout_config.' + field.payload.field + '.' + field.label) ? this.$t('checkout_config.' + field.payload.field + '.' + field.label) : field.label) : null"
                                       :placeholder="field.placeholder ? this.$t('checkout_config.' + field.payload.field + '.' + field.placeholder) : null"
                                       :disabled="processing||field.disabled"
                                       :message-not-found="dynamic_field_messageNotFound(field, 'checkout_config.' + field.payload.field)"
                                       :infinity-scroll="dynamic_field_infinityScroll(field)"
                                       :strict="field.typeahead && field.typeahead.strict"
                                       :min-date="form_dynamic_getDatepickerMinDate(field)"
                                       :max-date="form_dynamic_getDatepickerMaxDate(field)"
                                       :milliseconds="true"
                                       :disable-dates="form_dynamic_getDatepickerDisableDates(field)"
                                       @search="(value, render) => dynamic_field_typeahead(field, value, render)"/>
                        </div>

                        <div class="form-group text-center pt-3">
                            <button type="submit" class="btn btn-sm btn-primary" :disabled="processing">
                                <spinner v-if="processing"/>
                                {{processing ? t('button.submit.processing') : t('button.submit.cta')}}
                            </button>
                        </div>
                    </form>
                </panel>
            </template>

            <template #default>
                <!-- Products -->
                <views-order-submit-product v-for="product in products"
                                            v-model="model.products[product[productId]]"
                                            class="product"
                                            :key="product[productId]"
                                            :product="product"
                                            :disabled="processing"
                                            :model="v$.model.products[product[productId]]"/>

                <div v-if="products_message"><i>{{products_message}}</i></div>
            </template>
        </layout-two-column>
    </div>
</template>

<script>

    import {mapGetters} from "vuex"
    import useVuelidate from "@vuelidate/core";
    import MD5 from "crypto-js/md5";
    import {get} from "lodash"
    import {required, maxValue, minValue} from "@vuelidate/validators"

    import mixin_validator from "@/helpers/validator";
    import mixin_view from "@/mixins/view";
    import mixin_cart from "@/mixins/cart";
    import mixin_address from "@/mixins/address";
    import mixin_constraints from "@/mixins/constraints";
    import mixin_products from "@/mixins/products"
    import mixin_dynamic_field from "@/mixins/dynamic_field"
    import mixin_form_payload from "@/mixins/form/payload";
    import mixin_form_dynamic from "@/mixins/form/dynamic";
    import mixin_checkout_config from "@/mixins/checkout/config";
    import {ADDRESS_TFA, CHECKOUT_TAG_IMPERSONATOR, ORDER_STATUS} from "@/constants";

    import AddressForm from "@/components/address/AddressForm";
    import AddressSelector from "@/components/address/AddressSelector";
    import FormAutocomplete from "@/components/form/FormAutocomplete";
    import FormCheckbox from "@/components/form/FormCheckbox";
    import FormDate from "@/components/form/FormDate";
    import FormTextarea from "@/components/form/FormTextarea";
    import FormTextbox from "@/components/form/FormTextbox";
    import LayoutTwoColumn from "@/components/layout/LayoutTwoColumn";
    import ModalOrderOutOfStock from "@/modal/ModalOrderOutOfStock";
    import ModalOrderSubmitConfirm from "@/modal/ModalOrderSubmitConfirm";
    import ModalQuizProfile from "@/modal/ModalQuizProfile";
    import Panel from "@/components/Panel";
    import TextLabel from "@/components/TextLabel";
    import ViewsOrderSubmitProduct from "@/views/order/submit/ViewsOrderSubmitProduct";

    const ADDRESS_REFRESH_INTERVAL = 10000;
    const CART_REFRESH_INTERVAL = 60000;
    const ERROR_OUT_OF_STOCK = "Message: One or more of your requested items is out of stock.";

    export default {
        name: "ViewsOrderSubmitted",
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        mixins: [
            mixin_view,
            mixin_cart,
            mixin_address,
            mixin_constraints,
            mixin_products,
            mixin_validator,
            mixin_dynamic_field,
            mixin_form_payload,
            mixin_form_dynamic,
            mixin_checkout_config
        ],
        components: {
            AddressForm,
            AddressSelector,
            FormAutocomplete,
            FormCheckbox,
            FormDate,
            FormTextarea,
            FormTextbox,
            LayoutTwoColumn,
            Panel,
            TextLabel,
            ViewsOrderSubmitProduct,
        },
        data() {

            const forceNewAddress = 0 === this.$store.getters["user/address/items"].length
                && !this.$store.getters["user/address/denyAddressCreation"];

            return {
                t_path: "views.order.submit",
                loading: false,
                processing: false,
                ts: null,
                display: {
                    checkout: !forceNewAddress,
                    addressform: forceNewAddress,
                    addresscompleteform: false
                },
                model: {
                    addressId: null,
                    address: null,
                    products: {}
                },
                checkout: {
                    types: {
                        textbox: "FormAutocomplete",
                        textarea: "FormTextarea",
                        checkbox: "FormCheckbox",
                        datepicker: "FormDate",
                        label: "TextLabel"
                    }
                },
                tags: []
            }
        },
        computed: {
            ...mapGetters({
                productId: "user/cart/id",
                token: "user/token",
                products: "user/cart/items",
                addresses: "user/address/items",
                denyAddressCreation: "user/address/denyAddressCreation"
            }),
            checkoutConfig() {

                return this.checkout_config_getFields(
                    this.$store.getters["config/user/checkout/config"],
                    {tags: this.computedTags}
                );
            },
            checkoutConfigBeforeAddress() {

                return this.checkout_config_getFields(
                    this.$store.getters["config/user/checkout/configBeforeAddress"],
                    {tags: this.computedTags}
                );
            },
            checkoutConfigAfterAddress() {

                return this.checkout_config_getFields(
                    this.$store.getters["config/user/checkout/configAfterAddress"],
                    {tags: this.computedTags}
                );
            },
            forceNewAddress() {

                return 0 === this.addresses.length && !this.denyAddressCreation;
            },
            isImpersonated() {

                return null !== this.$store.getters["user/impersonatorToken"]
                    && null !== this.$store.getters["user/profile/firstName"];
            },
            computedTags() {

                const tags = this.tags.slice();

                if (true === this.isImpersonated) {

                    tags.push(CHECKOUT_TAG_IMPERSONATOR);
                }

                return tags;
            }
        },
        validations() {

            const model = {
                addressId: {
                    required: value => this.denyAddressCreation && 0 === this.addresses.length || !!value,
                    noAddress: value => value || !(this.denyAddressCreation && 0 === this.addresses.length)
                },
                products: {}
            };

            this.products.forEach(product => model.products[product[this.productId]] = {
                required,
                minValue: minValue(1),
                maxValue: maxValue(this.constraints_getProductAvailableAmount(product))
            });

            this.checkoutConfig.forEach(
                field => model[field.payload.field] = this.validator_createDynamicValidationRules(field.validation, {})
            );

            return {model};
        },
        methods: {
            setDisplay(enableType) {

                Object.keys(this.display).forEach(type => this.display[type] = type === enableType);
            },
            order() {

                this.processing = true;

                const request = {
                    hash: MD5(`${JSON.stringify(this.model.products)}${this.token}${this.ts}`).toString(),
                    addressId: this.model.addressId,
                    tags: this.tags,
                    ...this.checkoutConfig.reduce((result, field) => {

                        result[field.payload.field] = this.model[field.payload.field];

                        if ("datepicker" === field.type) {

                            result[field.payload.field] = this.form_payload_getDateUtcMilliseconds(this.model[field.payload.field]);
                        }

                        return result;
                    }, {})
                };

                this.$activity.log("order_submit", {
                    request,
                });

                return this.$store.dispatch("user/orders/post", request);
            },
            process() {

                this.processing = true;

                return this
                    .order()
                    .then(response => {

                        const query = {};

                        if (response.data.results.outOfStockSkus) {

                            this.$activity.log("order_submit_out_of_stock", {
                                outOfStockSkus: response.data.results.outOfStockSkus
                            });

                            query.outOfStockSkus = response.data.results.outOfStockSkus.join(",");
                        }

                        if (response.data.results.successMessage) {

                            this.$activity.log("order_submit_success_message", {
                                successMessage: response.data.results.successMessage
                            });

                            query.messageTranslations = JSON.stringify(response.data.results.successMessage);
                        }

                        response.data.results.order.products.forEach(orderProduct => {

                            if (ORDER_STATUS.PRE_PENDING_APPROVAL === orderProduct.lastStatus.status) {

                                query.messageOrderStatusError = 1;
                            }
                        });

                        if (response.data.results.orderNotes) {

                            query.notes = JSON.stringify(response.data.results.orderNotes);
                        }

                        // Navigate to order submitted page
                        this.$router.push({name: "order.submitted", query});
                    })
                    .catch(error => {

                        if (!error.response) {

                            return this.$toast.error(this.t("errors.requestError"), {
                                timeout: 5000
                            });
                        }

                        if (409 == error.response.status) {

                            // Clear cart
                            this.$store.dispatch("user/cart/clear");

                            // Navigate to order submitted page
                            return this.$router.push({name: "order.submitted"});
                        }

                        if (400 == error.response.status) {

                            const errorMessage = get(error, "response.data.results.0.msg");

                            // Check for it's out of stock issue
                            if (errorMessage && null !== errorMessage.match(ERROR_OUT_OF_STOCK)) {

                                // Display items out of stock
                                return this.$modal.show({
                                    component: ModalOrderOutOfStock,
                                    props: {
                                        items: get(error, "response.data.results.0.data")
                                            .map(sku => {

                                                // Find item for showing in modal
                                                const item = this.cart_find({sku});

                                                // Remove item from the cart
                                                this.cart_remove({sku});

                                                return item ? item : null;
                                            })
                                            .filter(item => !!item)
                                    }
                                });
                            }
                        }

                        this.base_error(error);
                    })
                    .finally(() => this.processing = false);
            },
            submit() {

                this.processing = false;

                const address = this.addresses.find(address => address._id === this.model.addressId);

                if (true === this.address_isIncompleted(address, {tags: this.computedTags})) {

                    return this.setDisplay("addresscompleteform");
                }

                if (this.$store.getters["user/profile/forceQuizProfile"]) {

                    return this
                        .$modal
                        .show({
                            component: ModalQuizProfile,
                            esc: false,
                            lg: true,
                            props: {
                                addressId: this.model.addressId
                            }
                        })
                        .then(() => this.submit(), () => true);
                }

                return this.$modal
                    .show({
                        component: ModalOrderSubmitConfirm,
                        props: {
                            addressId: this.model.addressId,
                            tags: this.computedTags
                        }
                    })
                    .then(() => this.process());
            },
            fetch() {

                this.loading = true;

                this.$store
                    .dispatch("user/cart/fetch")
                    .then(() => {

                        if (0 < this.products.length) {
                            this.$store
                                .dispatch("user/orders/getRequirements", {
                                    country: this.$store.getters["user/profile/country"],
                                    items: this.products.map(cartItem => ({
                                        [this.productId]: cartItem[this.productId],
                                        quantity: cartItem.quantity
                                    }))
                                })
                                .then(tags => this.tags.splice(0, this.tags.length, ...tags))
                        }
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.loading = false);
            }
        },
        watch: {
            checkoutConfig: {
                immediate: true,
                deep: true,
                handler(config) {

                    config.forEach(field => {

                        if ("undefined" === typeof this.model[field.payload.field]) {

                            this.model[field.payload.field] = "undefined" !== field.value
                                ? field.value
                                : null;

                            this.dynamic_field_watch(field, "model");
                        }
                    });
                }
            },
            products: {
                immediate: true,
                deep: true,
                handler(products) {

                    this.model.products = products.reduce((result, product) => {

                        result[product[this.productId]] = product.quantity;

                        return result;
                    }, {});
                }
            },
            "model.products": {
                deep: true,
                handler(products) {

                    Object.keys(products).forEach(productId => {

                        if (!this.v$.model.products[productId].$invalid) {

                            this.cart_setQuantity(
                                {[this.$store.getters["user/cart/id"]]: productId},
                                products[productId]
                            );
                        }
                    });
                }
            },
            "model.addressId": {
                immediate: true,
                handler() {

                    this.model.address = this.$store.getters["user/address/findItem"](this.model.addressId);
                }
            }
        },
        mounted() {

            this.ts = new Date().getTime();

            this.fetch();

            this.$store.dispatch("user/cart/refresh", {start: true, interval: CART_REFRESH_INTERVAL});

            if (ADDRESS_TFA) {

                this.$store.dispatch("user/address/refresh", {start: true, interval: ADDRESS_REFRESH_INTERVAL});
            }
        },
        beforeUnmount() {

            this.$store.dispatch("user/cart/refresh", false);

            if (ADDRESS_TFA) {

                this.$store.dispatch("user/address/refresh", false);
            }
        }
    }
</script>