import {get} from "lodash"
import {platform} from "@/api/platform";

let REFRESH_INTERVAL;

export default {
    namespaced: true,
    state: {
        id: "sku",
        loading: false,
        items: [],
        version: null,
        properties: {}
    },
    getters: {
        id: state => state.id,
        loading: state => state.loading,
        items: state => state.items,
        total: state => state.items.reduce((total, item) => total + item.quantity, 0),
        version: state => state.version,
        properties: state => state.properties,
        exists: state => item => -1 !== state.items.findIndex(i => i[state.id] === item[state.id])
    },
    mutations: {
        setLoading: (state, loading) => state.loading = loading,
        setItems: (state, items) => state.items = items,
        setVersion: (state, version) => state.version = version,
        setProperties: (state, properties) => state.properties = properties,
        setCart(state, cart) {

            state.items.splice(0, state.items.length, ...get(cart, "items", []));
            state.version = cart.version
            state.properties = cart.properties;
        }
    },
    actions: {
        fetch(context) {

            context.commit("setLoading", true);

            return platform
                .get(`/api/v2/users/${this.getters["user/id"]}/cart`)
                .then(response => context.commit("setCart", response.data.results))
                .finally(() => context.commit("setLoading", false));
        },
        addItem(context, id) {

            context.commit("setLoading", true);

            return platform
                .patch(`/api/v2/users/${this.getters["user/id"]}/cart`, {
                    action: "add",
                    [context.getters["id"]]: id,
                    quantity: 1
                })
                .then(response => context.commit("setCart", response.data.results))
                .finally(() => context.commit("setLoading", false));
        },
        setItemQuantity(context, data) {

            context.commit("setLoading", true);

            const item = context.getters["items"].find(item => item[context.getters["id"]] === data[context.getters["id"]]);
            const action = data.quantity > item.quantity ? "add" : "remove";
            const quantity = data.quantity > item.quantity
                ? data.quantity - item.quantity
                : item.quantity - data.quantity

            return platform
                .patch(`/api/v2/users/${this.getters["user/id"]}/cart`, {
                    action,
                    [context.getters["id"]]: data[context.getters["id"]],
                    quantity,
                })
                .then(response => context.commit("setCart", response.data.results))
                .finally(() => context.commit("setLoading", false));
        },
        removeItem(context, id) {

            return platform
                .patch(`/api/v2/users/${this.getters["user/id"]}/cart`, {
                    action: "remove",
                    [context.getters["id"]]: id,
                    quantity: context.getters["items"].find(item => item[context.getters["id"]] === id).quantity || 1,
                })
                .then(response => context.commit("setCart", response.data.results))
                .finally(() => context.commit("setLoading", false));
        },
        refresh(context, options) {

            if (options && options.start && options.interval) {

                clearInterval(REFRESH_INTERVAL);
                REFRESH_INTERVAL = setInterval(
                    () => platform
                        .get(`/api/v2/users/${this.getters["user/id"]}/cart`)
                        .then(response => context.commit("setCart", response.data.results)),
                    options.interval
                );

                return true;
            }

            clearInterval(REFRESH_INTERVAL);

            return false;
        },
        clear(context) {

            context.commit("setItems", []);
            context.commit("setVersion", process.env.VUE_APP_CART_VERSION);
            context.commit("setProperties", {});
        }
    },
    modules: {}
}