export default {
    empty: "Nothing to approve",
    saved: "Approvals submitted",
    review: "",
    message: {
        message: "Please note: All items available in DXC GEAR are centrally funded by DXC IT. You will not see a charge to your budget/cost center. While IT is managing the budget for DXC Gear, every order is a DXC expense. As the approving manager, we ask that you carefully consider each request and approve only when the item is necessary for your employee to complete their job responsibilities."
    },
    card: {
        name: {
            label: "Name"
        },
        date: {
            label: "Date"
        },
        details: {
            label: "Details"
        },
        price: {
            label: "Price"
        },
        manager: {
            label: "Manager"
        },
        requested: {
            label: "Requested by"
        },
        history: {
            cta: {
                load: "Prior approvals",
                loading: "Loading",
                show: "Prior approvals",
                hide: "Prior approvals"
            },
            approvals: {
                empty: "No prior approvals",
                item: {
                    status: {
                        approver: "by {approver}",
                        system: "Help Desk",
                        approved: "Approved",
                        rejected: "Not Approved",
                        unrequired: "Approval Not Required",
                        reason: "Reason",
                        transferred: "Transferred"
                    }
                }
            }
        },
        reason: {
            label: "Reason",
            placeholder: "Fill reason"
        },
        cta: {
            approve: {
                cta: "Approve",
                processing: "Saving"
            },
            disapprove: {
                cta: "Not Approve",
                processing: "Saving"
            }
        },
        saved: "Approvals submitted"
    }
}